<template>
   <div  class="inside-bg">
    <div class="container" style="padding-top:100px">
      <h1>Training Courses</h1>
    </div>
    <div class="container">
      <nav area-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">Training courses</li>
        </ol>
      </nav>
      <div class="" id="training-courses" v-if="courses.length !== 0 ">
        <div v-for="course in courses" :key="course.cid" class="card mb-2">
          <div class="card-body">
            <div @click="showCourseDetails(course)">{{course.course_name}}</div>
            <div><small><v-icon class="mdi" size="14px" color="#6c757d">{{$icons.school}}</v-icon> {{course.institute_name}}</small></div>
            <div class="text-right">
              <button class="btn btn-outline-primary" @click="showInterest($event, course)" v-if="!course.interested">Show interest</button>
              <span class="text-success" v-else>Interested</span>
            </div>
          </div>
        </div>
      </div>
    </div>
   </div>
</template>

<script>
import Actions from '../libraries/apiActions.js';
import '../assets/hire.css';
export default {
    name:'TrainingInstitutes',
    data: function(){
      return{
        institutes: [],
        action: new Actions(),
        filter: {
          course_id: null,
          city_id:null
        },
        req: {
          enquiry: '',
          course_id: [],
          interested: 1,
          institute_id: null
        },
        courses:[],
        locations: [],
        isLogin: false
      }
    },
    beforeMount(){
      this.getStates();
      // this.getInstitutes();
      this.isLogin = (this.$cookies.get("hire-seeker")&&this.$cookies.get("hire-seeker").token) ? true:false;
      this.getInstituteCourses();
    },
    methods: {
      showInterest: function(e, course){
        this.req.course_id = [course.cid];
        this.req.institute_id = course.institute_id;

        e.preventDefault();
        if(this.isLogin){
          let token = this.$cookies.get("hire-seeker").token;
          this.action.submitLead(this.req, token).then(res => {
          if(res.status){
            this.showMsgModal("Submitted interest");
            this.getInstituteCourses();
          }
          }).catch(err =>{
            console.log(err);
          })
        } else{
          this.redirect_from = this.$route.path;
          let url = '/jobseeker/register?src='+course.institute_name+'&redirect_from='+this.redirect_from;
          window.open(url, "_blank").focus();
        }
      },
      showCourseDetails: function(course){
        let param = course.course_name.replace(/[^A-Z0-9]+/ig, "-");
        if(param.length > 80){
          param = param.substring(0, 80);
        }
        param = param+'-'+course.cid;
        window.open(`/training-courses/${param}`,'_blank');
      },
      getInstituteCourses: function(){
        let req = {}
        if(this.$cookies.get("hire-seeker") && this.$cookies.get("hire-seeker").id){
         req.user_id = this.$cookies.get("hire-seeker").id;
        }
        console.log(req);
        this.action.getRegisteredCourses(req).then(res => {
          if(res.status){
            this.courses = res.courses;
          }
        }).catch(err =>{
          console.log(err);
        })
      },
      showMsgModal(msg = null) {
        this.boxOne = '';
        this.$bvModal.msgBoxOk(msg, {
            buttonSize: 'sm',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
        });
      },
      getStates: async function () {
          await this.action.getStates().then(async (data) => {
          this.locations.push({header: 'Top Metropolitan Cities'})
          await data.states.forEach(async (element) => {
              await element.cities.forEach(city => {
                  if(city.top_city == 1){
                      this.locations.push({text:city.city_name,value:city.city_id, s_id:city.state_id,group:'Top Metropolitan Cities'})
                  }
              });
              this.locations.push({divider:true})
              this.locations.push({header: element.state_name});
              element.cities.forEach(city => {
                  if(city.top_city == 0){
                      this.locations.push({text:city.city_name,value:city.city_id, s_id:city.state_id,group:element.state_name})
                  }
              });
          });
      });
  },
    }
}
</script>
<style scoped>
#training-courses .card {
  cursor: pointer;
 }
 .breadcrumb {
    background-color: transparent;
    margin-bottom: 0;
  }
  v-text-field__details{display: none !important;}
</style>